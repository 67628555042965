<template>
  <div class="forgot-password">
    <Header />
    <b-container class="forgot-password-container">
      <b-row class="justify-content-center">
        <div>
          <b-card-group deck>
            <b-card>
              <h1>Permintaan Reset Password</h1>
              <template>
                <div>
                  <form @submit.prevent="handleSubmit">
                    <h3 class="forgot-password-label">User ID</h3>
                    <vs-input
                      id="email"
                      type="text"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="client_id"
                      placeholder="contoh: RJKL0001"
                      required
                      @keyup="handleInputOnKeyup"
                    />
                    <!-- <div class="error my-2" v-if="!$v.email.email">
                      <p>Format email belum sesuai</p>
                    </div> -->

                    <div class="error my-2" v-if="failedForgotPassword">
                      <p>User ID belum terdaftar</p>
                    </div>

                    <div class="pt-4">
                      <b-button
                        block
                        :disabled="loading"
                        type="submit"
                        @click="handleSubmit()"
                        class="btn-tertiary py-2"
                      >
                        <div v-if="loading">
                          <div class="spinner-border spinner-border-sm"></div>
                          Loading
                        </div>
                        <div v-else>Submit</div>
                      </b-button>
                    </div>
                  </form>
                </div>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-row>

      <div class="modal-forgot-password">
        <b-modal
          id="modal-success-forgot-password"
          v-model="successForgotPassword"
          size="md"
          class="modal-success-forgot-password"
          centered
          hide-footer
        >
          <div class="d-block text-center">
            <img
              src="@/assets/img/forgot-password/success-forgot-password.svg"
            />
            <p>Permintaan Reset Password Sukses</p>
            <h1 class="text-dark">Cek Email Anda</h1>
          </div>
        </b-modal>
      </div>
    </b-container>
    <Footer4 />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import Footer4 from "@/components/partials/footer/Footer4.vue";

import { required } from "vuelidate/lib/validators";
import instance from "@/utils/api";

export default {
  name: "ForgotPassword",
  metaInfo: {
    title: "Lupa Password | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer4,
  },
  data() {
    return {
      client_id: "",
      successForgotPassword: false,
      failedForgotPassword: false,
      loading: false,
      processing: false,
    };
  },
  validations: {
    client_id: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
    }),

    isCompleted() {
      return this.email;
    },
  },
  methods: {
    handleInputOnKeyup() {
      this.client_id = this.client_id.toUpperCase();
    },
    handleSubmit() {
      if (!this.processing) {
        this.processing = true;
        let data = new FormData();
        data.append("client_id", this.client_id);

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        instance
          .post(`${this.app_url}2827`, data, axiosConfig) //staging
          .then((res) => {
            // console.log("RESPONSE RECEIVED: ", res);
            res;
            instance
              .post(`${this.app_url}2824`, data, axiosConfig) //staging
              .then((res) => {
                // console.log("RESPONSE RECEIVED: ", res);
                res;
                this.successForgotPassword = true;
                this.processing = false;
              })
              .catch(() => {
                this.loading = !false;
                setTimeout(() => {
                  this.loading = !true;
                }, 2000);

                this.processing = false;
              });

            this.processing = false;
          })
          .catch((err) => {
            if (err.response.data.data.message == "User id not found") {
              this.processing = false;
              this.failedForgotPassword = err.response.data.data.message;
            } else {
              this.processing = false;
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password {
  background: #f3f4f6;
}
.forgot-password-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #e53e3e;
  }
}

.vs-con-input-label {
  width: 100%;
}

.modal-forgot-password {
  h1 {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #111827;
  }
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #374151;
  }
}

.forgot-password-label {
  padding-top: 16px;
}

.forgot-password-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
}

.custom-control-label {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.custom-control-label:before {
  background-color: #e60012 !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e60012 !important;
}

.card-body {
  padding: 48px;
  box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
    0px 4px 6px rgba(107, 114, 128, 0.05);
  border-radius: 5px;
  width: 500px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

@media only screen and (min-width: 1366px) {
  .forgot-password-container {
    padding-bottom: 480px;
  }
}

@media only screen and (max-width: 768px) {
  .forgot-password-container {
    padding-bottom: 60px;
  }
  .back-nav {
    padding: 0px 240px 20px 0px;
  }
  .card-body {
    width: 100%;
  }
}
</style>
